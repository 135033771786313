import { Link } from 'gatsby';
import { default as React } from 'react';
import { Highlight, Index, Snippet, useHits } from 'react-instantsearch';
import LinkComponent from '../../utils/linkComponent';

const PageHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Link Clicked', {
								title: hit.name ? hit.name : null,
								link: hit.slug ? hit.slug : null,
								path: window.location.pathname,
								report_type: 'New',
								collection_type: 'Page',
								static_component_title: 'Search Result',
								url: window.location.href,
								strapi_id: hit.strapi_id ? hit.strapi_id : null,
								matchedwords: hit._highlightResult?.name?.matchedWords
									? hit._highlightResult.name.matchedWords
									: null,
							});
							props.setFocus(false);
							props.setExpanded(false);
							props.setClearSearch(true);
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='name' hit={hit} tagName='mark' />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} tagName='mark' />
				</div>
			))}
		</>
	);
};

const SessionsHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`/program/${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Link Clicked', {
								title: hit.title ? hit.title : null,
								link: hit.slug ? hit.slug : null,
								path: window.location.pathname,
								report_type: 'New',
								collection_type: 'Session',
								static_component_title: 'Search Result',
								url: window.location.href,
								strapi_id: hit.strapi_id ? hit.strapi_id : null,
								matchedwords: hit._highlightResult?.name?.matchedWords
									? hit._highlightResult.name.matchedWords
									: null,
							});
							props.setFocus(false);
							props.setExpanded(false);
							props.setClearSearch(true);
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='title' hit={hit} />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} />
				</div>
			))}
		</>
	);
};

const SpeakersHit = (props) => {
	const { hits } = useHits(props);
	return (
		<>
			{hits.map((hit) => (
				<div key={hit.objectID}>
					<LinkComponent
						to={`/people/${hit.slug}`}
						id={hit.objectID}
						onClick={() => {
							window.analytics.track('Link Clicked', {
								title: hit.title ? hit.title : null,
								link: hit.slug ? hit.slug : null,
								path: window.location.pathname,
								report_type: 'New',
								collection_type: 'Speaker',
								static_component_title: 'Search Result',
								url: window.location.href,
								strapi_id: hit.strapi_id ? hit.strapi_id : null,
								matchedwords: hit._highlightResult?.name?.matchedWords
									? hit._highlightResult.name.matchedWords
									: null,
							});
							props.setFocus(false);
							props.setExpanded(false);
							props.setClearSearch(true);
						}}
					>
						<p style={{ marginBottom: '0.6rem' }}>
							<Highlight attribute='full_name' hit={hit} />
						</p>
					</LinkComponent>
					<Snippet attribute='excerpt' hit={hit} />
				</div>
			))}
		</>
	);
};

const HitsInIndex = ({ index, setFocus, setExpanded, setClearSearch }) => (
	<Index indexName={index.name}>
		<h5
			style={{
				color: 'var(--secondary_6)',
				backgroundColor: '#509bb9',
				padding: '0.5em',
			}}
		>
			{index.title}
		</h5>
		{/* <HitCount /> */}
		{index.name === 'Speakers' ? (
			<SpeakersHit
				className='Hits'
				setFocus={setFocus}
				setExpanded={setExpanded}
				setClearSearch={setClearSearch}
			/>
		) : index.name === 'Sessions' ? (
			<SessionsHit
				className='Hits'
				setFocus={setFocus}
				setExpanded={setExpanded}
				setClearSearch={setClearSearch}
			/>
		) : index.name === 'Pages' ? (
			<PageHit
				className='Hits'
				setFocus={setFocus}
				setExpanded={setExpanded}
				setClearSearch={setClearSearch}
			/>
		) : null}
	</Index>
);

const SearchResult = ({
	indices,
	className,
	setFocus,
	setExpanded,
	setClearSearch,
}) => (
	<div className={className}>
		{indices.map((index) => (
			<HitsInIndex
				index={index}
				key={index.name}
				setFocus={setFocus}
				setExpanded={setExpanded}
				setClearSearch={setClearSearch}
			/>
		))}
	</div>
);
export default SearchResult;
