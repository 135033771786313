// Search.js
import algoliasearch from 'algoliasearch/lite';
import React, { createRef, useState, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch';
import { ThemeProvider } from 'styled-components';
import StyledSearchBox from './styledSearchBox';
import StyledSearchResult from './styledSearchResult';
import StyledSearchRoot from './styledSearchRoot';
import useClickOutside from './useClickOutside';

const theme = {
	foreground: '#050505',
	background: 'white',
	faded: '#888',
};

export default function Search({ indices, setExpanded }) {
	const rootRef = createRef();
	const [query, setQuery] = useState('');
	const [hasFocus, setFocus] = useState(false);
	const [clearSearch, setClearSearch] = useState(false);
	const searchClient = useMemo(
		() =>
			algoliasearch(
				process.env.GATSBY_ALGOLIA_APP_ID,
				process.env.GATSBY_ALGOLIA_SEARCH_KEY
			),
		[]
	);

	useClickOutside(rootRef, () => {
		setFocus(false);
		setClearSearch(true);
	});

	return (
		<ThemeProvider theme={theme}>
			<StyledSearchRoot ref={rootRef}>
				<InstantSearch searchClient={searchClient} indexName='Pages'>
					<StyledSearchBox
						onChange={(q) => {
							setQuery(q);
							if (clearSearch) setClearSearch(false); // reset trigger if typing
						}}
						onFocus={() => setFocus(true)}
						hasFocus={hasFocus}
						clearTrigger={clearSearch} // pass the flag here
					/>
					<StyledSearchResult
						show={query && query.length > 0 && hasFocus}
						indices={indices}
						setFocus={setFocus}
						setExpanded={setExpanded}
						setClearSearch={setClearSearch} // pass setter for results
					/>
				</InstantSearch>
			</StyledSearchRoot>
		</ThemeProvider>
	);
}
