import styled, { css } from 'styled-components';
import SearchResult from './searchResult';

const Popover = css`
	position: absolute;
	top: 100%;
	left: 0;
	width: 80vw;
	max-width: 30em;
	max-height: 80vh;
	overflow-y: auto; /* Allows scrolling */
	-webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
	z-index: 1002;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1em;
	border-radius: 4px;
	background: ${({ theme }) => theme.background};

	@media (max-width: 768px) {
		width: 100%;
		max-width: none; /* On mobile, it takes the full width */
		left: 0;
	}
`;

export default styled(SearchResult)`
	display: ${(props) => (props.show ? `block` : `none`)};
	${Popover}

	.HitCount {
		display: flex;
		justify-content: flex-end;
	}

	.Hits {
		ol {
			list-style: none;
			margin-left: 0;
		}

		li.ais-Hits-item {
			margin-bottom: 1em;

			a {
				color: ${({ theme }) => theme.foreground};

				h4 {
					margin-top: 0;
					margin-bottom: 0.2em;
				}
			}
		}
	}

	.ais-PoweredBy {
		display: flex;
		justify-content: flex-end;
		font-size: 80%;

		svg {
			width: 130px;
		}
	}
`;
